var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content",class:{ 'box has-background-light': _vm.isEmbedded }},[_vm._m(0),_c('p',{staticClass:"subtitle is-6"},[_vm._v("Monday - Friday 09:00 - 17:00")]),_vm._m(1),_vm._m(2),_vm._m(3),_c('hr',{staticClass:"hr"}),_vm._m(4),_c('p',{staticClass:"subtitle is-6"},[_vm._v("Monday - Friday 08:00 - 18:00*")]),_c('p',[_vm._v(" *Outside of these hours there is a 24/7 service offering limited support to report issues relating to your system application ")]),_c('hr',{staticClass:"hr"}),_vm._m(5),_c('p',{staticClass:"subtitle is-6"},[_vm._v("Monday - Friday 09:00 - 17:00")]),_c('hr',{staticClass:"hr"}),_c('p',[_vm._v(" If you would like to know more about our range of products and services please contact our sales team ")]),_vm._m(6),_c('p',{staticClass:"subtitle is-6"},[_vm._v("Monday - Friday 09:00 - 17:00")]),_c('hr',{staticClass:"hr"}),_c('div',{staticClass:"level"},[_c('a',{staticClass:"level-left",attrs:{"href":"mailto:automotive.enquiries@uk.experian.com"}},[_vm._v("automotive.enquiries@uk.experian.com")]),(_vm.isEmbedded)?_c('a',{staticClass:"level-right help has-text-soft",on:{"click":function($event){return _vm.$emit('collapse')}}},[_vm._v(" Hide contact details "),_vm._m(7)]):_vm._e()])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"title is-spaced is-6"},[_vm._v(" Client Support - "),_c('a',{attrs:{"href":"tel:0800 597 7229"}},[_vm._v("0800 597 7229")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v(" Select "),_c('span',{staticClass:"has-text-primary"},[_vm._v("option 1")]),_vm._v(" to query the data recorded on the AutoCheck Report ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v(" Select "),_c('span',{staticClass:"has-text-primary"},[_vm._v("option 2")]),_vm._v(" for queries relating to accessing the AutoCheck site and payment ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v(" Select "),_c('span',{staticClass:"has-text-primary"},[_vm._v("option 3")]),_vm._v(" for account and payment ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"title is-spaced is-6"},[_vm._v(" Technical Support - "),_c('a',{attrs:{"href":"tel:0115 934 4444"}},[_vm._v("0115 934 4444")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"title is-spaced is-6"},[_vm._v(" Vehicle Mileage Check - "),_c('a',{attrs:{"href":"tel:0844 481 5661"}},[_vm._v("0844 481 5661")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"title is-spaced is-6"},[_vm._v(" Sales Enquiries - "),_c('a',{attrs:{"href":"tel:0870 411 1200"}},[_vm._v("0870 411 1200")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"icon has-text-info"},[_c('i',{staticClass:"far fa-chevron-up"})])
}]

export { render, staticRenderFns }