<template>
  <div class="content" :class="{ 'box has-background-light': isEmbedded }">
    <p class="title is-spaced is-6">
      Client Support -
      <a href="tel:0800 597 7229">0800 597 7229</a>
    </p>
    <p class="subtitle is-6">Monday - Friday 09:00 - 17:00</p>
    <p>
      Select
      <span class="has-text-primary">option 1</span> to query the data recorded
      on the AutoCheck Report
    </p>
    <p>
      Select
      <span class="has-text-primary">option 2</span> for queries relating to
      accessing the AutoCheck site and payment
    </p>
    <p>
      Select
      <span class="has-text-primary">option 3</span> for account and payment
    </p>
    <hr class="hr" />
    <p class="title is-spaced is-6">
      Technical Support -
      <a href="tel:0115 934 4444">0115 934 4444</a>
    </p>
    <p class="subtitle is-6">Monday - Friday 08:00 - 18:00*</p>
    <p>
      *Outside of these hours there is a 24/7 service offering limited support
      to report issues relating to your system application
    </p>
    <hr class="hr" />
    <p class="title is-spaced is-6">
      Vehicle Mileage Check -
      <a href="tel:0844 481 5661">0844 481 5661</a>
    </p>
    <p class="subtitle is-6">Monday - Friday 09:00 - 17:00</p>
    <hr class="hr" />
    <p>
      If you would like to know more about our range of products and services
      please contact our sales team
    </p>
    <p class="title is-spaced is-6">
      Sales Enquiries -
      <a href="tel:0870 411 1200">0870 411 1200</a>
    </p>
    <p class="subtitle is-6">Monday - Friday 09:00 - 17:00</p>
    <hr class="hr" />
    <div class="level">
      <a class="level-left" href="mailto:automotive.enquiries@uk.experian.com"
        >automotive.enquiries@uk.experian.com</a
      >
      <a
        v-if="isEmbedded"
        @click="$emit('collapse')"
        class="level-right help has-text-soft"
      >
        Hide contact details
        <span class="icon has-text-info">
          <i class="far fa-chevron-up" />
        </span>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ContactFullDetails',
  props: {
    isEmbedded: {
      type: Boolean,
      required: false,
      default: false
    }
  }
}
</script>
